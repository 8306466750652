<template lang="">
    <div id="kitchen" :style="kitchenStyle">
      <el-row :gutter="20">
        <!-- Meal Categories Card -->
        <el-col :span="12">
          <el-card>
            <h3>Meal Categories</h3>
            <el-form :model="newCategory" @submit.prevent="addCategory">
              <el-form-item label="Category Name" prop="name">
                <el-input v-model="newCategory.name" placeholder="Enter category name" />
              </el-form-item>
              <el-button type="primary" @click="addCategory">Add Category</el-button>
            </el-form>
  
            <el-table :data="categories" style="width: 100%">
              <el-table-column prop="name" label="Category Name" width="180" />
              <el-table-column label="Actions">
                <template v-slot="scope">
                  <el-button @click="editCategory(scope.row)">Edit</el-button>
                  <el-button type="danger" @click="deleteCategory(scope.row.id)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
  
        <!-- Meals Card -->
        <el-col :span="12">
          <el-card>
            <h3>Meals</h3>
            <el-form :model="newMeal" @submit.prevent="addMeal">
              <el-form-item label="Meal Name" prop="name">
                <el-input v-model="newMeal.name" placeholder="Enter meal name" />
              </el-form-item>
              <el-form-item label="Meal Price" prop="price">
                <el-input v-model="newMeal.price" placeholder="Enter meal price" />
              </el-form-item>
              <el-form-item label="Meal Category" prop="category_id">
                <el-select v-model="newMeal.category_id" placeholder="Select category">
                  <el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id" />
                </el-select>
              </el-form-item>
  
              <!-- Image Upload for Meal -->
              <el-form-item label="Meal Image" prop="image">
                <input type="file" @change="handleImageUpload" />
              </el-form-item>
  
              <el-button type="primary" @click="addMeal">Add Meal</el-button>
            </el-form>
  
            <el-table :data="meals" style="width: 100%">
              <el-table-column prop="name" label="Meal Name" width="180" />
              <el-table-column prop="price" label="Price" width="100" />
              <el-table-column prop="category.name" label="Category" width="180" />
  
              <!-- Display Meal Image -->
              <el-table-column label="Image">
                <template v-slot="scope">
                  <img :src="`${endpointImage}/storage/${scope.row.image}`" 
                       alt="Meal Image" 
                       style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover; overflow: hidden;" />
                </template>
              </el-table-column>
  
              <el-table-column label="Actions">
                <template v-slot="scope">
                  <el-button @click="editMeal(scope.row)">Edit</el-button>
                  <el-button type="danger" @click="deleteMeal(scope.row.id)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import kitchenBg from "@/assets/img/kitchen.jpg";
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });


  
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token'); // Get token from local storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
  export default {
    data() {
      return {
        newCategory: {
          name: ''
        },
        newMeal: {
          name: '',
          price: '',
          category_id: null,
          image: null, // Image file
        },
        categories: [],
        endpointImage:'https://cale.caledonian.ly',
        meals: [],
        kitchenStyle: {
          backgroundImage: `url(${kitchenBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        },
      };
    },
    methods: {
      // Fetch all categories
      fetchCategories() {
        api.get('/categories')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error("Error fetching categories", error);
          });
      },
      // Fetch all meals
      fetchMeals() {
        api.get('/meals')
          .then(response => {
            this.meals = response.data;
          })
          .catch(error => {
            console.error("Error fetching meals", error);
          });
      },
      // Handle Image Upload
      handleImageUpload(event) {
        const file = event.target.files[0];
        this.newMeal.image = file; // Assign the selected file to newMeal.image
      },
      // Add new category
      addCategory() {
        api.post('/categories-store', this.newCategory)
          .then(response => {
            this.categories.push(response.data.category);
            this.newCategory.name = ''; // Reset the form
          })
          .catch(error => {
            console.error("Error adding category", error);
          });
      },
      // Edit category
      editCategory(category) {
        this.newCategory = { ...category }; // Set form with current category details for editing
      },
      // Delete category
      deleteCategory(categoryId) {
        api.delete(`/categories/${categoryId}`)
          .then(response => {
            this.categories = this.categories.filter(c => c.id !== categoryId);
          })
          .catch(error => {
            console.error("Error deleting category", error);
          });
      },
      // Add new meal
      addMeal() {
        // Use FormData to send image along with meal data
        const formData = new FormData();
        formData.append('name', this.newMeal.name);
        formData.append('price', this.newMeal.price);
        formData.append('category_id', this.newMeal.category_id);
        if (this.newMeal.image) {
          formData.append('image', this.newMeal.image);
        }
  
        api.post('/meals', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          this.meals.push(response.data.meal);
          this.newMeal = { name: '', price: '', category_id: null, image: null }; // Reset the form
        })
        .catch(error => {
          console.error("Error adding meal", error);
        });
      },
      // Edit meal
      editMeal(meal) {
        this.newMeal = { ...meal }; // Set form with current meal details for editing
      },
      // Delete meal
      deleteMeal(mealId) {
        api.delete(`/meals/${mealId}`)
          .then(response => {
            this.meals = this.meals.filter(m => m.id !== mealId);
          })
          .catch(error => {
            console.error("Error deleting meal", error);
          });
      }
    },
    mounted() {
      this.fetchCategories();
      this.fetchMeals();
    }
  };
  </script>
  
  <style scoped>
  /* You can add any styles here if needed */

  #Kitchen{
    width: 100%;
    height: 400px; /* Set a height or the background won't show */
  }

  .el-form >>> label{
color: aliceblue !important;
background-color: rgb(0, 0, 88);
  }
  </style>
  